<template>
  <VDialog
    v-model="visibility"
    max-width="600px"
    @click:outside="showConfirmDialog"
    :persistent="isChanged"
    content-class="cross__dialog"
    scrollable
    :transition="$dialogTransition"

  >
    <VCard :height="$vuetify.breakpoint.smAndDown ? 'auto' : ''">
          <div class="cross__inner">
        <VBtn fab small  elevation="0" @click="showConfirmDialog">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <VCardTitle>
        <VRow no-gutters justify="space-between" align="center" class="mx-4">
        <span class="headline" :class="{ 'text-body-1': $vuetify.breakpoint.xs }">
          {{ $t('cell.create_cell') }}
          </span>

        </VRow>
      </VCardTitle>
      <VCardText>
        <VContainer>
          <div class="text-center" v-if="loading">
            <VProgressCircular
              :size="50"
              color="primary"
              indeterminate
            ></VProgressCircular>
          </div>
          <div class="" v-else>
            <VRow>
              <VCol :cols="this.permissions.can_dop_data ? 12 : 6">
                <VTextField
                  outlined dense
                  :label="$t('form.name')"
                  v-model="cell.name"
                  required
                  :error-messages="nameErrors"
                  hide-details="auto"
                  color="success"
                  @keypress="validationForTitle($event)"
                  @paste="validationForTitle($event)"
                ></VTextField>
              </VCol>
              <VCol cols="12" md="6" v-if="this.permissions.can_dop_data" >
                <v-autocomplete
                  :items="depots"
                  :item-value="'uuid'"
                  :item-text="'name'"
                  outlined
                  :label="$t('form.storage')"
                  :no-data-text="$t('table.noData')"
                  dense
                  v-model="cell.deport_uuid"
                  :error-messages="uuidErrorsDeport"
                  @change="filterSector({deport_uuid:cell.deport_uuid})"
                  small-chips
                  deletable-chips
                  hide-details="auto"
                  color="success"
                  @keypress="validationForWarehouses($event)"
                  @paste="validationForWarehouses($event)"
                />
              </VCol>
              <VCol cols="12" md="6">
                <v-autocomplete
                  :disabled="cell.deport_uuid === '' && this.permissions.can_dop_data"
                  :label="$t('form.sector')"
                  :items="Object.values(sectors)"
                  :item-value="'uuid'"
                  :item-text="'name'"
                  :no-data-text="$t('table.noData')"
                  outlined
                  dense
                  @change="filterSector({uuid_sector:cell.uuid_sector})"
                  v-model="cell.uuid_sector"
                  :error-messages="uuidErrors"
                  hide-details="auto"
                  deletable-chips
                  small-chips
                  color="success"
                  @keypress="validationForTitle($event)"
                  @paste="validationForTitle($event)"
                />
              </VCol>
              <VCol cols="12" md="6">
              <v-autocomplete
                class="pa-0"
                dense
                :label="$t('cell.parent_cell')"
                v-model="cell.parent_cell"
                outlined
                hide-details="auto"
                :items="cells"
                :item-value="'uuid'"
                :item-text="'name'"
                color="success"
                :disabled="cell.parent_cell === '' && cell.uuid_sector === ''"
                clearable
                @keypress="validationForTitle($event)"
                @paste="validationForTitle($event)"
              />
               </VCol>
              <VCol cols="12" md="6">
                <VTextField
                  outlined
                  dense
                  :label="$t('table.ns_code')"
                  v-model="cell.ns_code"
                  required
                  :error-messages="nsCodeErrors"
                  color="success"
                  hide-details="auto"
                  @keypress="validationForNSCode($event)"
                  @paste="validationForNSCode($event)"
                  :maxLength="16"
                ></VTextField>
              </VCol>
              <VCol cols="12">
                <VTextarea
                  outlined dense
                  :label="$t('form.description')"
                  required
                  v-model="cell.description"
                  no-resize
                  counter
                  maxlength="255"
                  rows="5"
                  color="success"
                  @keypress="validationForDepartment($event)"
                  @paste="validationForDepartment($event)"
                ></VTextarea>
              </VCol>
            </VRow>
          </div>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VRow class="my-0 py-0">
          <VCol col="6">
            <VBtn
              dark
              block
              class="w-100 br-10 text-transform-none"
              @click="showConfirmDialog"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t('btn.cancel') }}
            </VBtn>
          </VCol>
          <VCol col="6">
            <VBtn
              block
              class="success-bg text-white text-transform-none br-10 w-100"
              @click="onCreate"
              :disabled="!isChanged"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
               {{ $t('btn.create') }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
     <ConfirmLeftDialog
    :visible="visibleConfirm"
    @close="visibleConfirm = false"
    @delete="onClose"
    />
  </VDialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';
import ConfirmLeftDialog from '@/components/dialog/ConfirmLeftDialog.vue';
import nsCodeMask from '@/validators/nsCodeMask';
import language from '../../mixins/language';
import notifications from '../../mixins/notifications';
import depotService from '../../services/request/depot/depotService';
import EventBus from '../../events/EventBus';
import user from '../../mixins/user';
import {
  uniqueByUUID,
  validationForDepartment,
  validationForNSCode,
  validationForNumbers,
  validationForTitle,
  validationForWarehouses
} from '../../mixins/helpers';

export default {
  name: 'CreateCellDialog',
  mixins: [language, notifications, validationMixin, user],
  validations: {
    cell: {
      name: { required },
      uuid_sector: { required },
      ns_code: { required, nsCodeMask },
      deport_uuid: {
        // eslint-disable-next-line func-names
        required: requiredIf(function () {
          return this.permissions.can_dop_data;
        }),
      },
    },
  },
  components: { ConfirmLeftDialog },
  data: () => ({
    loading: false,
    visibleConfirm: false,
    isChanged: false,
    isDisabledParentCells: true,
    cells: [],
    cell: {
      name: '',
      description: '',
      uuid_sector: '',
      deport_uuid: '',
      ns_code: '',
      parent_cell:''
    },
    depots: [],
  }),
  watch: {
    cell: {
      deep: true,
      handler() {
        // // orderDialog(e);
        const values = Object.values(this.cell);
        if (values.filter((item) => item).length) {
          this.isChanged = true;
        } else {
          this.isChanged = false;
        }
      },
    },
  },
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    sectors: {
      required: true,
    },
  },
  async mounted() {
    try {
      this.depots = await depotService.getDepotsList();
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    validationForDepartment,
    validationForNumbers,
    validationForWarehouses,
    validationForTitle,
    validationForNSCode,
    async getCellsList(uuidDeport,uuidSector) {
      try {
        const params = {
          order: 'DESC',
          orderby: 'time_created',
        }
        if(uuidDeport){
          params.sector_details__uuid_deport  = uuidDeport
        }
        if(uuidSector){
          params.uuid_sector  = uuidSector
        }
        this.isLoadingParent = true;
        const newItems = await depotService.getCellsList(params);
        // this.cells = newItems.filter(e => e.uuid !== deportUuid);
        this.cells = uniqueByUUID(newItems);
        this.isLoadingParent = false;
      } catch (e) {
        this.isLoadingParent = false;
        this.setLoading(false);
        console.log(e)
      }
    },
    onClose() {
      this.cell = {
        name: '',
        description: '',
        uuid_sector: '',
        deport_uuid: '',
        ns_code: '',
        parent_cell:''
      };
      this.$v.$reset();
      this.visibility = false;
    },
    showConfirmDialog() {
      if (this.isChanged) {
        this.visibleConfirm = true;
      } else {
        this.onClose();
      }
    },
    filterSector(data) {
      if (!data) {
        this.cell.parent_cell = '';
        this.cells = [];
        this.sectors = []
      }
      if(data.uuid_sector){
        this.getCellsList(this.cell.deport_uuid,data.uuid_sector)
      }
      if(data.uuid_sector === null){
        this.isDisabledParentCells = !data.uuid_sector
        this.cell.parent_cell = ''
      }
      if(data.deport_uuid === null ){
        this.cell.deport_uuid = ''
        this.cell.uuid_sector = ''
        this.cell.parent_cell = ''
      }
      this.isDisabledParentCells = !data.uuid_sector || !this.cell.deport_uuid
      if(this.cell.deport_uuid){
        EventBus.$emit('filter-sectors', {
          uuid_deport: this.cell.deport_uuid,
        });
      }
    },
    async onCreate() {
      // orderDialog('this.cell', this.cell);
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append('name', this.cell.name);
          formData.append('uuid_sector', this.cell.uuid_sector);
          // if (this.permissions.can_dop_data) {
          //   formData.append('uuid_deport', this.cell.deport_uuid);
          // }
          formData.append('ns_code', this.cell.ns_code);
          if (this.cell.description) {
            formData.append('description', this.cell.description);
          }
          if (this.cell.parent_cell) {
            formData.append('uuid_parent', this.cell.parent_cell);
          }
          await depotService.addCell(formData);
          EventBus.$emit('cell-created');
          this.setSuccessNotification(this.$t('cell.cell_created'));
          this.loading = false;
          this.clearCell();
          this.visibility = false;
          this.$v.$reset();
        } catch (e) {
          this.loading = false;
          console.log(e)
        }
      }
    },
    clearCell() {
      Object.keys(this.cell).forEach((key) => {
        this.cell[key] = '';
      });
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit('close');
      },
    },
    uuidErrorsDeport() {
      const errors = [];
      if (!this.$v.cell.deport_uuid.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.cell.deport_uuid.required
      && errors.push(this.$t('form.errors.StorageRequired'));
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.cell.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.cell.name.required
      && errors.push(this.$t('form.errors.NameRequired'));
      return errors;
    },
    uuidErrors() {
      const errors = [];
      if (!this.$v.cell.uuid_sector.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.cell.uuid_sector.required
      && errors.push(this.$t('form.errors.SectorRequired'));
      return errors;
    },
    nsCodeErrors() {
      const errors = [];
      if (!this.$v.cell.ns_code.$dirty) {
        return errors;
      }
      if (!this.$v.cell.ns_code.nsCodeMask) {
        errors.push(this.$t('form.errors.NsCodeValidation'));
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.cell.ns_code.required
      && errors.push(this.$t('form.errors.NsCodeRequired'));
      return errors;
    },
  },
};
</script>

<style scoped>

</style>
