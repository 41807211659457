<template>
  <CellsComponent />
</template>

<script>
import store from '@/store';
import CellsComponent from '../../components/cells/CellsComponent.vue';

export default {
  name: 'Cells',
  components: {
    CellsComponent,
  },
  beforeRouteEnter(to, from, next) {
    if (store.getters['auth/getPermissions'].can_view_cells_page) {
      next();
      return;
    }
    next(from);
  },
};
</script>

<style scoped>

</style>
